
import { StateCheckItem } from "./statecheck";

export const checkEval: Array<StateCheckItem> = [
    {
        statecode: 'IA',
        tags: ['dui'],
        onlyCourt: false,
        message: `<strong>Attention:</strong> The state of Iowa does not allow assessments/&#8203;evaluations to be completed for anyone who currently resides in Iowa who had an OWI in Iowa.
If you need an assessment for an Iowa OWI but live out of state, you may complete this assessment.
Please ask us for our out of state provider form. `
    },
    {
        statecode: 'GA',
        tags: ['dui'],
        onlyCourt: false,
        message: `<strong>Warning:</strong> If you are required to complete an assessment/evaluation for the state of GA you must go through a local provider in GA approved by DBHDD.  If you take our out of state assessment you MUST get prior approval before doing so and NO REFUNDS be provided once the assessment is completed.`
    },
    {
        statecode: 'IL',
        tags: ['dui'],
        onlyCourt: false,
        message: `<strong>Attention:</strong> If you live in the state of IL and have a requirement in IL to complete a risk reduction class or assessment, you must go through a local provider in your state.
        If you have a requirement in IL but live out of the state, you may be able to complete our programs with prior approval. `
    },
    {
        statecode: 'MI',
        tags: ['dui'],
        onlyCourt: false,
        message: `Residents of Michigan that need to complete a MI assessment must go through a local in-state provider.  Those that have a MI assessment requirement that live out of state may complete an assessment from an out of state provider with prior approval.`
    },
    {
        statecode: 'MO',
        tags: ['dui'],
        onlyCourt: false,
        message: `<strong>Warning</strong> If you are a resident of the state of Missouri and had a DUI in this state, you must go through a local SATOP provider.  
        If you had a DUI in MO but live out of state, 
        then you can complete our assessment using the "SATOP Comparable Form".`
    },
    {
        statecode: 'NC',
        tags: ['dui'],
        onlyCourt: false,
        message: `<strong>Attention:</strong> If you live in NC and had a DUI in NC you must go through one of their state approved providers.  
If you live out of the state of NC, and you must complete a substance assessment, it is best to contact a North Carolina provider before you begin your services.
If you complete our substance abuse assessment, you must choose a North Carolina DWI services provider to review and approve it.
Please be advised we DO NOT refund completed assessments.`
    },
    {
        statecode: 'OK',
        tags: ['dui'],
        onlyCourt: false,
        message: `<strong>Warning:</strong> This assessment must be completed by an ADSAC assessor certified by the Oklahoma Department of Mental Health and Substance Abuse Services and cannot be taken by an out of state provider for a DUI in Oklahoma. We do not provide a red stamp for this evaluation. If you live out of state you may be able to complete our assessment with prior approval.`
    },
    {
        statecode: 'OR',
        zipCodes: ["97702", "97701", "97756", "97703", "97739", "97759", "97760","97539",
            "97707", "97413", "97712", "97709", "97708", "97741", "97759", "97760",
            "97734", "97761", "97350", "97711", "97730", "97701", "97754", "97760",
            "97753", "97712", "97751", "97752"],
        tags: ['dui'],
        onlyCourt: false,
        message: `<strong>Warning</strong> If you live in Deschutes, Jefferson or Crook Counties in the state of Oregon, you must use a local in-state provider for your DUI evaluation.`
    },
    {
        statecode: 'NY',
        tags: ['dui'],
        onlyCourt: false,
        message: `<strong>Warning</strong> 
        NY residents who also have a requirement to complete an evaluation must also contact a local OASIS approved provider in the state of NY.
Out of state residents may be able to have an evaluation completed by an out of state provider with prior approval.`
    },
    {
        statecode: 'WA',
        tags: ['dui'],
        onlyCourt: false,
        message: `<strong>Warning</strong> If you live in WA state and have a WA state drivers license you must go through a local state approved and certified agency. If you live out of state, you can complete our assessment.`
    },
    {
        statecode: 'WI',
        tags: ['dui'],
        onlyCourt: false,
        message: `<strong>Warning</strong> The state of WI requires anyone required to complete an assessment to do so with a state licensed counselor in the state you live in. If you have a WI assessment requirement you can only complete ours if you live in CA as we are licensed in this state.`
    }
];