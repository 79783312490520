import * as $ from 'jquery';

export interface StateCheckItem {
    statecode: string;
    message: string;
    onlyCourt: boolean;
    tags: Array<string>;
    zipCodes?: Array<string>;
}

export class StateCheck {
    statecheckitems: Array<StateCheckItem>;
    classtags: Array<string>;
    $warning: JQuery;
    $state: JQuery;
    $courtddCourt?: JQuery;
    $courtddPersonal?: JQuery;
    $zipcode?: JQuery;

    constructor(statecheckitems: Array<StateCheckItem>, classtags: Array<string>) {
        this.statecheckitems = statecheckitems;
        this.classtags = classtags;
    }

    setup($warning: JQuery, $state: JQuery, $courtddCourt?: JQuery, $courtddPersonal?: JQuery, $zipcode?: JQuery) {
        const self = this;
        this.$warning = $warning;
        this.$state = $state;
        this.$courtddCourt = $courtddCourt;
        this.$courtddPersonal = $courtddPersonal;
        this.$zipcode = $zipcode;

        this.$state.on("change", function () {
            self.doCheckControls();
        });

        if (this.$zipcode)
            this.$zipcode.on("change", function () {
                self.doCheckControls();
            });

        if (this.$courtddCourt) {
            this.$courtddCourt.on("change", function () {
                self.doCheckControls();
            });
        }
        if (this.$courtddPersonal) {
            this.$courtddPersonal.on("change", function () {
                self.doCheckControls();
            });
        }
        this.doCheckControls();
    }

    getZipcode(): string | null {
        if (this.$zipcode) {
            const zip = <string>this.$zipcode.val();
            return zip.trim().substring(0, 5);
        }
        return null;
    }

    doCheckControls() {
        const state = <string>this.$state.val();
        if (state) {
            const isCourt =
                !(this.$courtddCourt) || (this.$courtddCourt.is(":checked"));
            const zipcode = this.getZipcode();

            this.doCheck(state, isCourt, zipcode);
        }
        else {
            this.$warning.hide();
        }
    }

    doCheck(statecode: string, isCourt: boolean, zipcode: string | null) {
        for (const item of this.statecheckitems) {
            if (item.statecode === statecode) {
                if (this.doCheckState(item, isCourt, zipcode)) {
                    return;
                }
            }
        }

        this.$warning.hide();
    }

    // Returns true if we needed to show a warning
    doCheckState(item: StateCheckItem, isCourt: boolean, zipcode: string | null): boolean {
        // see if ANY tag matches
        for (const classtag of this.classtags) {
            for (const statetag of item.tags) {
                // do we need to check for a zip code match
                if (item.zipCodes && item.zipCodes.length > 0) {
                    if (!zipcode) {
                        return false;
                    }
                    if (!item.zipCodes.includes(zipcode)) {
                        return false;
                    }
                }

                if (classtag === statetag && (!item.onlyCourt || isCourt)) {
                    this.$warning.html(item.message).show();
                    return true;
                }
            }
        }
        return false;
    }
}